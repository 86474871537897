// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

export default ({
  location,
  canonical,
  siteUrl,
  pageTitle,
  siteTitle,
  pageTitleFull,
}) => {
  const isSubPage = pageTitle && location.pathname !== '/';

  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'CafeOrCoffeeShop',
      url: canonical,
      name: pageTitle || siteTitle,
      alternateName: pageTitleFull,
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Iowa City',
        addressRegion: 'IA',
        postalCode: '52240',
        streetAddress: '26 S. Clinton Street',
      },
      hasMap: 'https://maps.google.com/?cid=6550957362240400698',
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '41.6604385',
        longitude: '-91.5343647',
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        reviewCount: '44',
      },
      acceptsReservations: 'Yes',
      menu: 'https://www.cortadoic.com/#menu',
      openingHours: ['Mo-Sa 7:00-18:00', 'Su 8:00-16:00'],
      priceRange: '$',
      currenciesAccepted: 'USD',
      paymentAccepted: [
        'http://purl.org/goodrelations/v1#ByInvoice',
        'http://purl.org/goodrelations/v1#Cash',
        'http://purl.org/goodrelations/v1#COD',
        'http://purl.org/goodrelations/v1#DirectDebit',
        'http://purl.org/goodrelations/v1#AmericanExpress',
        'http://purl.org/goodrelations/v1#Discover',
        'http://purl.org/goodrelations/v1#MasterCard',
        'http://purl.org/goodrelations/v1#VISA',
      ],
      servesCuisine: [
        'Salad',
        'Sandwich',
        'Coffee',
        'Espresso',
        'Beer',
        'Wine',
        'Cortado',
      ],
      telephone: '(319) 519-2340',
      image:
        'https://s3.amazonaws.com/www.cortadoic.com/images/logos/logo_green.png',
      photo:
        'https://s3.amazonaws.com/www.cortadoic.com/images/banners/bg_pano.jpeg',
      email: 'info@cortadoic.com',
    },
  ];

  if (isSubPage) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }

  return schema;
};
