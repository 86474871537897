import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: block;
    list-style: none;
    padding: 0;

    ${MEDIA.MIN_TABLET`
      display: flex;
    `};

    li {
      text-transform: uppercase;
      font-size: 1.3rem;
      text-align: center;

      ${MEDIA.TABLET`
        display: grid;
      `};

      ${MEDIA.MIN_TABLET`
        text-align: left;
      `};

      & + li {
        margin-left: 0;

        ${MEDIA.MIN_TABLET`
          margin-left: 2rem;
        `};
      }

      ${MEDIA.MIN_TABLET`
        margin-left: 1rem;
      `};
    }
  }
`;
