import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.header`
  background-color: #507f2f;
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;

  ${MEDIA.MIN_TABLET`
    padding: 1.5rem 1.25rem;
  `};

  ${MEDIA.MIN_LAPTOP`
    display: flex;
    padding: 1.5rem 3rem;
  `};

  ${MEDIA.MIN_DESKTOP`
    padding: 1.5rem 4rem;
  `};

  > a {
    display: flex;
  }

  > a > div {
    margin-right: 0.5rem;
  }

  nav {
    margin-top: 2rem;

    ${MEDIA.MIN_LAPTOP`
      margin-top: 0;
    `};

    a {
      color: #ffffff;
      // transition: color 0.2s ease;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 500;
      border: none;
      border-radius: 3px;
      padding: 16px 18px;
      background-position: center;
      transition: background 0.8s;
      white-space: nowrap;

      &:hover {
        color: #ffffff;
        // text-decoration: underline;
        background: #426628 radial-gradient(circle, transparent 1%, #426628 1%)
          center/15000%;
      }

      &:active {
        background-color: var(--tertiary);
        background-size: 100%;
        transition: background 0s;
      }
    }

    svg {
      fill: #ffffff;
      margin-top: -12px;
      padding-top: 6px;
      margin-bottom: -4px;
    }
  }
`;
