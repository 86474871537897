import React, { useState } from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Navigation, ListEmail, ListLast, SideMenu } from './nav.css';

// See https://github.com/briodev/gatsby-react-burger-menu-starter/blob/master/src/components/navigation/main-navbar.js

export default () => {
  const [menuState, setMenuOpen] = useState({ menuOpen: false });

  const closeMenu = () => {
    setMenuOpen({ menuOpen: false });
  };

  return (
    <>
      <Navigation>
        <ul>
          <li>
            <AnchorLink to="/#order" title="Order Now"></AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#menu" title="Menu"></AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#about" title="Learn about us and our mission">
              <span>About</span>
            </AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#photo" title="Checkout our gallery">
              <span>Photos</span>
            </AnchorLink>
          </li>
          <li>
            <a
              rel="noopener"
              title="Call us at 319.519.2340"
              href="tel:3195192340"
            >
              319.519.2340
            </a>
          </li>
          <ListEmail>
            <a
              rel="noopener"
              title="Email us at orders@cortadoic.com"
              href="mailto:orders@cortadoic.com"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
              </svg>
              <span>orders@cortadoic.com</span>
            </a>
          </ListEmail>
          <ListLast>
            <a
              rel="noopener"
              title="Find us on Facebook"
              href="https://www.facebook.com/CortadoIC"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
              </svg>
            </a>
          </ListLast>
        </ul>
      </Navigation>
      <SideMenu>
        <Menu isOpen={menuState.menuOpen}>
          <Link className="menu-item" to="/#order" onClick={() => closeMenu()}>
            Order Now
          </Link>

          <Link className="menu-item" to="/#menu" onClick={() => closeMenu()}>
            Menu
          </Link>

          <Link className="menu-item" to="/#about" onClick={() => closeMenu()}>
            About
          </Link>

          <Link className="menu-item" to="/#photo" onClick={() => closeMenu()}>
            Photos
          </Link>
          <a
            className="menu-item"
            rel="noopener"
            title="Call us at 319.519.2340"
            href="tel:3195192340"
            onClick={() => closeMenu()}
          >
            319.519.2340
          </a>
          <a
            className="menu-item"
            rel="noopener"
            title="Email us at orders@cortadoic.com"
            href="mailto:orders@cortadoic.com"
            onClick={() => closeMenu()}
          >
            orders@cortadoic.com
          </a>
        </Menu>
      </SideMenu>
    </>
  );
};

// const Nav = () => (
//   <Container>
//     <ul>
//       <li>
//         <AnchorLink to="/#order" title="Order Now"></AnchorLink>
//       </li>
//       <li>
//         <AnchorLink to="/#menu" title="Menu"></AnchorLink>
//       </li>
//       <li>
//         <AnchorLink to="/#about" title="Learn about us and our mission">
//           <span>About</span>
//         </AnchorLink>
//       </li>
//       <li>
//         <AnchorLink to="/#photo" title="Checkout our gallery">
//           <span>Photos</span>
//         </AnchorLink>
//       </li>
//       <li>
//         <a rel="noopener" title="Call us at 319.519.2340" href="tel:3195192340">
//           319.519.2340
//         </a>
//       </li>
//       <ListEmail>
//         <a
//           rel="noopener"
//           title="Email us at orders@cortadoic.com"
//           href="mailto:orders@cortadoic.com"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//           >
//             <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
//           </svg>
//           <span>orders@cortadoic.com</span>
//         </a>
//       </ListEmail>
//       <ListLast>
//         <a
//           rel="noopener"
//           title="Find us on Facebook"
//           href="https://www.facebook.com/CortadoIC"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//           >
//             <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
//           </svg>
//         </a>
//       </ListLast>
//     </ul>
//   </Container>
// );

// export default Nav;
