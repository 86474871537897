import React from 'react';
import { Container, Copy } from './footer.css';
import Nav from 'components/footer/nav';

const Footer = () => (
  <>
    <Container>
      <Nav />
      <Copy>© 2020. Made by Trade in Cool.</Copy>
    </Container>
  </>
);

export default Footer;
