import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
// import posed from 'react-pose';
import { Container } from './header.css';
import Nav from 'components/header/nav';
import Logo from 'components/header/logo';

// Example of a component-specific page transition
// const AnimatedContainer = posed.header({
//   enter: {
//     y: 0,
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
//   exit: {
//     y: '-100%',
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
// });

// const Header = ({ title }) => (
const Header = () => (
  // <AnimatedContainer>
  <>
    <Container>
      <Logo />
      <Nav />
    </Container>
  </>
  // </AnimatedContainer>
);

// Header.propTypes = {
//   title: PropTypes.string.isRequired,
// };

export default Header;
