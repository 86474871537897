import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const BrandContainer = styled.div`
  flex: auto;

  ${MEDIA.MIN_LAPTOP`
    flex: none;
  `};

  svg {
    height: 48px;
    width: auto;
  }
`;
