import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Navigation = styled.nav`
  display: none;

  ${MEDIA.MIN_LAPTOPH`
    display: block;
  `};

  ul {
    list-style: none;
    padding: 0;
    // margin-left: -18px;
    display: grid;
    grid-gap: 3.2rem;
    
    ${MEDIA.MIN_LAPTOPH`
      display: flex;
      grid-gap: 2.2rem;
      margin-left: 0;
    `};

    ${MEDIA.MIN_DESKTOP`
      grid-gap: 3.2rem;
    `};

    li {
      text-transform: uppercase;
      font-size: 1.3rem;

      & + li {
        margin-left: 0;

        // ${MEDIA.MIN_LAPTOPH`
        //   margin-left: 1rem;
        // `};
      }
    }
  }
`;

export const ListEmail = styled.li`
  ${MEDIA.MIN_LAPTOPH`
  margin-left: 0;
  `};
  svg {
    display: none;

    ${MEDIA.MIN_LAPTOPH`
      display: inline-block;
    `};
  }

  span {
    display: inline-block;

    ${MEDIA.MIN_LAPTOPH`
      display: none;
    `};
  }
`;

export const ListLast = styled.li`
  display: none;

  ${MEDIA.MIN_LAPTOPH`
    display: inline-block;
    margin-left: 0;
  `};
`;

export const SideMenu = styled.div`
  /* Individual item */
  .bm-item {
    display: inline-block;
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #ffffff;
    transition: color 0.2s;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }

  /* The rest copied directly from react-burger-menu docs */
  /* Position and sizing of burger button */
  .bm-burger-button {
    //position: fixed;
    position: absolute;
    width: 36px;
    height: 30px;
    //right: 36px;
    right: 20px;
    //top: 36px;
    top: 20px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fefefe;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #507f2f;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: 100vh;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #507f2f;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
  }

  .bm-menu-wrap {
    left: 0;
  }

  ${MEDIA.MIN_LAPTOPH`
    display: none;
  `};
`;
