import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.main`
  background-color: #ffffff;
  padding: 2rem 1rem;
  // max-width: 60rem;
  max-width: 100%;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  ${MEDIA.MIN_TABLET`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 4rem;
  `};

  ${MEDIA.MIN_LAPTOP`
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
  `};
`;
