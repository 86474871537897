import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Copy = styled.div`
  padding: 4rem 0 0 0;

  ${MEDIA.MIN_TABLET`
    padding: 0;
  `};
`;

export const Container = styled.footer`
  padding: 3rem 4rem;
  margin-bottom: 4rem;
  // max-width: 60rem;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  `};

  ${MEDIA.MIN_LAPTOP`
    max-width: 120rem;
  `};

  a {
    color: var(--primary);
    transition: color 0.2s ease;
    text-decoration: none;
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-weight: 700;

    &:hover {
      color: var(--secondary);
      text-decoration: underline;
    }
  }
`;
