import { createGlobalStyle } from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';
import { accent } from 'constants/theme';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  :root {
    --primary: #333333;
    --secondary: #9c27b0;
    --tertiary: #62b2e3;
    --light: #fafafd;
    --grey: #cccccc;
    --crete: #507f2f;
    --brown: #822329;
    --trendypink: #9c5fa2;
    // --viking: #62b2e3;
    --viking: #339bdb;
    // --tango: #ef7729;
    --tango: #ee701b;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  ::selection {
    background-color: var(--primary);
    color: var(--light);
  }

  body {
    font-family: Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: var(--primary);
    background-color: var(--grey);
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: var(--trendypink);
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  .brand {
    .gatsby-image-wrapper {
      margin-right: 0.5rem;
    }

    .gatsby-image-wrapper + .gatsby-image-wrapper {
      margin-right: 0;
    }
  }

  input {
    width: 2.75rem;
    height: 1.75rem;
    padding: 0.25rem;
    margin: 0 0.75rem;
    background-color: transparent;
    border: 1px solid var(--secondary);
    border-radius: 10%;
    text-align: center;
  }

  .menu {
    padding: 2rem 2.25rem 1rem;
    margin: 0 auto;
    max-width: 50rem;
    background-color: var(--light);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 660px) {
    .menu {
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  .menu-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .logo {
    width: 280px;
    margin: 0 auto 2rem;
    display: block;
    grid-column: 1 / 3;
  }

  @media (min-width: 660px) {
    .logo {
      margin-bottom: 0;
    }
  }

  .mains {
    grid-column: 1 / 2;
  }

  .mains-name {
    margin: 0;
    flex-grow: 1;
  }

  .mains-description {
    flex: 1 1 100%;
    margin: 0.75rem 0 0;
    color: var(--secondary);
  }

  .aside {
    grid-column: 2 / 3;
  }

  .extras {
    color: var(--secondary);
  }

  .extras + .extras {
    margin-top: 1.5rem;
  }

  @media (min-width: 660px) {
    .extras + .extras {
      margin-top: 2.5rem;
    }
  }

  .extras .menu-item {
    margin-bottom: 0.75rem;
  }

  .extras-heading {
    margin: 0 0 1.5rem;
    border-bottom: 1px solid var(--secondary);
  }

  .extras-name {
    flex-grow: 1;
  }

  .total {
    grid-column: 1 / 3;
    margin-top: 2.5rem;
    padding: 0.75rem 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
    color: var(--secondary);
    background-color: var(--light);
    border-top: 1px solid var(--secondary);
    box-shadow: 0 -0.5rem 0.75rem 0.5rem var(--light);
  }

  @media (min-width: 660px) {
    .total {
      margin-top: 0;
    }
  }

  .total-title {
    margin: 0;
  }

  .total-price {
    font-size: 1.5rem;
  }

  .group__price {
    text-align: center;
  }
`;
