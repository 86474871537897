import React from 'react';
// import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Container } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <AnchorLink to="/#order" title="Order Now"></AnchorLink>
      </li>
      <li>
        <AnchorLink to="/#menu" title="Menu"></AnchorLink>
      </li>
      <li>
        <AnchorLink to="/#about" title="Learn about us and our mission">
          <span>About</span>
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="/#photo" title="Checkout our gallery">
          <span>Photos</span>
        </AnchorLink>
      </li>
      <li>
        <a
          rel="noopener"
          title="Email us at orders@cortadoic.com"
          href="mailto:orders@cortadoic.com"
        >
          orders@cortadoic.com
        </a>
      </li>
      <li>
        <a rel="noopener" title="Call us at 319.519.2340" href="tel:3195192340">
          319.519.2340
        </a>
      </li>
    </ul>
  </Container>
);

export default Nav;
