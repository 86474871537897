import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { BrandContainer } from './logo.css';
// import Img from 'gatsby-image';
import LogoFull from '../../../images/logo_full.svg';

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        logoFull: file(relativePath: { eq: "logo_full.png" }) {
          childImageSharp {
            fixed(height: 48) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logoOne: file(relativePath: { eq: "logo_p110.png" }) {
          childImageSharp {
            fixed(height: 48) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logoTwo: file(relativePath: { eq: "logo_green.png" }) {
          childImageSharp {
            fixed(height: 48) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    // render={(data) => (
    render={() => (
      <BrandContainer className="brand">
        <LogoFull />
        {/* <Img
          fixed={data.logoFull.childImageSharp.fixed}
          alt="Cortado Coffee & Cafe"
          loading="eager"
        />
        <Img
          fixed={data.logoOne.childImageSharp.fixed}
          alt="Cortado Coffee & Cafe Logo"
          loading="eager"
        />
        <Img
          fixed={data.logoTwo.childImageSharp.fixed}
          alt="Cortado Coffee & Cafe Text"
          loading="eager"
        /> */}
      </BrandContainer>
    )}
  />
);

export default Logo;
